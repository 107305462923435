import React from 'react'
import { Gallery } from '@roar/components'
import { Flex } from 'rebass/styled-components'
import Layout from '../modules/Layout'

const GallerySlider = (props) => {
  const { media } = props

  return (
    <Gallery variant="slider">
      {media.map((m) => {
        return (
          <Flex width={[1, 1]}>
            <Layout layout={[m]} />
          </Flex>
        )
      })}
    </Gallery>
  )
}

export default GallerySlider
