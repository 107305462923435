import React from 'react'
import { Gallery } from '@roar/components'
import { mapperWithFunction } from '../common/utils'
import { Flex } from 'rebass/styled-components'
import ImageWrapper, { formatImage } from '../modules/ImageWrapper'
import { Media } from '../Media'
import styled from 'styled-components'
import RichTextRenderer from '../components/RichTextRenderer'

const GALLERY_MAP = {
  items: ({ media }) => {
    if (!media) return []
    return media.map((m, index) => {
      return {
        id: index,
        headline: m.caption,
        caption: m.image.description,
        image: formatImage(m),
      }
    })
  },
  style: ['style'],
  description: ['description'],
}

const StyledMedia = styled(Media)`
  width: 100%;
`

const GalleryList = (props) => {
  const galleryProps = mapperWithFunction(props, GALLERY_MAP)

  return (
    <Flex {...galleryProps.style}>
      <StyledMedia greaterThanOrEqual="lg">
        <Gallery
          variant="list"
          isMobile={false}
          {...galleryProps}
          description={galleryProps.description && <RichTextRenderer content={galleryProps.description} />}
        >
          {props.media.map((media) => {
            return <ImageWrapper {...media} />
          })}
        </Gallery>
      </StyledMedia>
      <StyledMedia lessThan="lg">
        <Gallery variant="list" isMobile={true} {...galleryProps} />
      </StyledMedia>
    </Flex>
  )
}

export default GalleryList
