import React from 'react'
import { Gallery } from '@roar/components'
import { mapperWithFunction } from '../common/utils'
import { useMenu } from '../data/hooks/menu'
import { MENU_NAMES } from '../common/constants'
import ImageWrapper from '../modules/ImageWrapper'
import RichTextRenderer from '../components/RichTextRenderer'

const GALLERY_MAP = {
  style: ['style'],
  subtitle: ['subtitle', 'subtitle'],
  description: ['description'],
}

const GalleryDestinations = (props) => {
  const { subtitle } = mapperWithFunction(props, GALLERY_MAP)
  const { description } = mapperWithFunction(props, GALLERY_MAP)
  const { headline, items } = useMenu(MENU_NAMES.DESTINATIONS)

  const galleryItems = items.map((i, index) => {
    return {
      id: index,
      key: index,
      headline: i.title,
      link: i.link,
    }
  })

  return (
    <Gallery
      variant="destination"
      items={galleryItems}
      headline={headline}
      subtitle={subtitle}
      description={description && <RichTextRenderer content={description} />}
    >
      {items.map((media) => {
        return <ImageWrapper key={media.id} image={media.imageLarge} title={media.imageLarge.title || ''} />
      })}
    </Gallery>
  )
}

export default GalleryDestinations
