import React from 'react'
import { Box } from 'rebass/styled-components'
import Layout from '../modules/Layout'
import { Gallery } from '@roar/components'
import { mapper } from '../common/utils'

const GALLERY_PROPS_MAP = {
  style: ({ style = {} }) => {
    if (!style) return {}
    return Object.keys(style).reduce((result, item) => {
      if (style[item]) {
        result[item] = style[item]
      }

      return result
    }, {})
  },
}

const GalleryArrowsCenter = (props) => {
  const { style } = mapper(props, GALLERY_PROPS_MAP)

  const { layout } = props

  return (
    <Box {...style}>
      <Gallery variant="arrowsCenter" sliderSpeed={12}>
        {(activeIdx, setDimsCallback) => {
          return layout.map((l, idx) => {
            const layoutProps = {
              ...l,
              setDimsCallback,
              isSlide: true,
              isActiveSlide: idx === activeIdx,
            }
            return (
              <Box width={[1, '75%']} mx="auto">
                <Layout layout={[layoutProps]} />
              </Box>
            )
          })
        }}
      </Gallery>
    </Box>
  )
}

export default GalleryArrowsCenter
