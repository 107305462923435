import React from 'react'
import { graphql } from 'gatsby'
import GallerySlider from '../components/GallerySlider'
import GalleryList from '../components/GalleryList'
import GalleryDestinations from '../components/GalleryDestinations'
import GalleryArrows from '../components/GalleryArrows'
import GalleryPress from '../components/GalleryPress'
import GalleryArrowsCenter from '../components/GalleryArrowsCenter'

const GalleryMapper = {
  Slider: GallerySlider,
  List: GalleryList,
  Destination: GalleryDestinations,
  Arrows: GalleryArrows,
  Press: GalleryPress,
  ArrowsCenter: GalleryArrowsCenter,
}

const GalleryWrapper = (props) => {
  const { displayType, description } = props

  const GalleryComponent = GalleryMapper[displayType]

  if (GalleryComponent) {
    return <GalleryComponent {...props} description={description ?? null} />
  }

  return null
}

export default GalleryWrapper

export const layoutGallery = graphql`
  fragment LayoutGallery on ContentfulLayoutGallery {
    id
    name
    displayType
    subtitle {
      subtitle
    }
    description {
      raw
      references {
        __typename
        ... on ContentfulHeading {
          ...HeadingFields
        }
        ... on ContentfulContentGeneric {
          ...ContentGeneric
        }
      }
    }
    style {
      bg
      pb
      pl
      pr
      pt
      py
    }
    media {
      __typename
      ... on ContentfulImage {
        ...Image
        altText
        image {
          title
          description
          gatsbyImageData(layout: FULL_WIDTH, width: 720, quality: 90)
        }
      }
      ... on ContentfulVideo {
        ...Video
      }
    }
  }
`
