import React from 'react'
import { Box } from 'rebass/styled-components'
import { Gallery } from '@roar/components'
import { setupLink } from '../common/utils'
import ImageWrapper from '../modules/ImageWrapper'

const GalleryPress = (props) => {
  const { style = {}, layout } = props

  if (!layout && !layout.length) return null

  const formatItems = layout.map((l) => {
    const { links } = l

    const blogData = links.map((l) => {
      const linkObject = {
        __typename: l.__typename,
        to: `/${l.postType.name.toLowerCase()}/${l.year}/${l.month}/${l.slug}`,
      }
      return { link: setupLink(linkObject) }
    })

    return {
      blogData,
      headline: l.headline,
      image: <ImageWrapper image={l.pressImage} />,
    }
  })

  return (
    <Box {...style}>
      <Gallery variant="press" items={formatItems}></Gallery>
    </Box>
  )
}

export default GalleryPress
